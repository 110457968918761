import { Experiments, SiteAssetsExperimentMetadata } from '@wix/thunderbolt-symbols'
import { siteAssetsModules } from './siteAssetsModules'

const createExperiments = <T extends Record<string, SiteAssetsExperimentMetadata>>(obj: T) => obj

export const beckySpecs = createExperiments({
	'specs.thunderbolt.lazyLoadImages': { modules: siteAssetsModules },
	'specs.thunderbolt.musicPlayerWaitUntilPlayed': { modules: siteAssetsModules },
	'specs.thunderbolt.fixGapBelowTextboxonMobileSite': { modules: siteAssetsModules },
	'specs.thunderbolt.fixPinnedSectionZIndex': { modules: siteAssetsModules },
	'specs.thunderbolt.fixRatingsInputLeftShift': { modules: siteAssetsModules },
	'specs.thunderbolt.supportPositionDesignVar': { modules: siteAssetsModules },
	dm_changeCSMKeys: { modules: siteAssetsModules },
	'specs.thunderbolt.deprecateAppId': { modules: siteAssetsModules },
	'specs.thunderbolt.UseWixDataItemService': { modules: siteAssetsModules },
	'specs.thunderbolt.UseWixDataGetRequests': { modules: siteAssetsModules },
	'specs.thunderbolt.breakingBekyCache': { modules: siteAssetsModules },
	'specs.thunderbolt.DatePickerPortal': { modules: siteAssetsModules },
	'specs.thunderbolt.LinkBarPlaceholderImages': { modules: siteAssetsModules },
	'specs.thunderbolt.presenceApi': { modules: siteAssetsModules },
	'specs.thunderbolt.presenceWithoutChat': { modules: siteAssetsModules },
	'specs.thunderbolt.repeater_keyboard_navigation': { modules: siteAssetsModules },
	'specs.thunderbolt.WRichTextResponsiveTagsInBlocksWidget': { modules: siteAssetsModules },
	'specs.thunderbolt.proGalleryMasterInfo': { modules: siteAssetsModules },
	'specs.thunderbolt.freemiumBannerMobileUsePreferredApp': { modules: siteAssetsModules },
	'specs.thunderbolt.externalComponents': { modules: siteAssetsModules },
	'specs.editor-elements.allowRefPointerEventsInEditMode': { modules: siteAssetsModules },
	'specs.thunderbolt.dropdownOptionStyle': { modules: siteAssetsModules },
	'specs.thunderbolt.oneDocStrictMode': { modules: siteAssetsModules },
	'specs.thunderbolt.prefetchPageResourcesVeloApi': { modules: siteAssetsModules },
	'specs.thunderbolt.shouldUseViewTransition': { modules: siteAssetsModules },
	'specs.thunderbolt.native_css_mappers_video': { modules: siteAssetsModules },
	'specs.thunderbolt.edixIsInFirstFold': { modules: siteAssetsModules },
	'specs.thunderbolt.shouldUseResponsiveImages': { modules: siteAssetsModules },
	'specs.thunderbolt.DDMenuMigrateCssCarmiMapper': { modules: siteAssetsModules },
	'specs.thunderbolt.supportSpxInEEMappers': { modules: siteAssetsModules },
	'specs.thunderbolt.AnalyticsElementsClicks': { modules: siteAssetsModules },
	'specs.thunderbolt.ImageXLinkStyles': { modules: siteAssetsModules },
	'specs.thunderbolt.roundBordersInResponsiveContainer': { modules: siteAssetsModules },
	'specs.thunderbolt.allowWEBPTransformation': { modules: siteAssetsModules },
	'specs.thunderbolt.customDropdownForMobile': { modules: siteAssetsModules },
	'specs.studio.breadcrumbsSection': { modules: siteAssetsModules },
	'specs.thunderbolt.designableListForMobileDropdown': { modules: siteAssetsModules },
	'specs.thunderbolt.app_reflow_with_lightboxes': { modules: siteAssetsModules },
	'specs.thunderbolt.fiveGridLineStudioSkins': { modules: siteAssetsModules },
	'specs.thunderbolt.keepTextInputHeight': { modules: siteAssetsModules },
	'specs.thunderbolt.fetchBlocksDevCenterWidgetIds': { modules: siteAssetsModules },
	'specs.thunderbolt.root_components_carmi': { modules: siteAssetsModules },
	'specs.thunderbolt.fetch_widget_iteratively': { modules: siteAssetsModules },
	'specs.thunderbolt.WRichTextPropsMapper': { modules: siteAssetsModules },
	'specs.thunderbolt.doNotInflateSharedParts': { modules: siteAssetsModules },
	'specs.thunderbolt.BuilderComponent': { modules: siteAssetsModules },
	'specs.thunderbolt.CollapsibleTextNewPreviewStateImplementation': { modules: siteAssetsModules },
	'specs.thunderbolt.TextInputAutoFillFix': { modules: siteAssetsModules },
	'specs.thunderbolt.TPA3DGalleryEEUrl': { modules: siteAssetsModules },
	'specs.thunderbolt.SetNoCacheOnRefComponentError': { modules: siteAssetsModules },
	'specs.thunderbolt.fixVectorImageShouldScaleStroke': { modules: siteAssetsModules },
	'specs.thunderbolt.fixResponsiveBoxContainerLayoutClass': { modules: siteAssetsModules },
	'specs.thunderbolt.removeHeaderFooterWrappers': { modules: siteAssetsModules },
	'specs.thunderbolt.updateLoginSocialBarMenuItemsSDKData': { modules: siteAssetsModules },
	'specs.thunderbolt.carouselGalleryImageFitting': { modules: siteAssetsModules },
	'specs.thunderbolt.fontsFromExternal': { modules: siteAssetsModules },
	'specs.thunderbolt.fixTextInputRaceCondition': { modules: siteAssetsModules },
	'specs.thunderbolt.fixAnchorSections': { modules: siteAssetsModules },
	'specs.thunderbolt.allowCustomElementForAll': { modules: siteAssetsModules },
	'specs.thunderbolt.fixDisabledLinkButtonStyles': { modules: siteAssetsModules },
	'specs.thunderbolt.buttonUdp': { modules: siteAssetsModules },
	'specs.thunderbolt.enableOneDoc': { modules: siteAssetsModules },
	'specs.thunderbolt.mergeSkinDefaultsFromRegistry': { modules: siteAssetsModules },
	'specs.thunderbolt.UseEEImpress': { modules: siteAssetsModules },
	'specs.thunderbolt.allowAnimatedImageTransform': { modules: siteAssetsModules },
	'specs.thunderbolt.PayPalButtonRedirectFlow': { modules: siteAssetsModules },
	'specs.thunderbolt.maxWidthInCustomScale': { modules: siteAssetsModules },
	'specs.thunderbolt.remoteStructureRenderer': { modules: siteAssetsModules },
	'specs.thunderbolt.A11yWPhotoPopupSemanticsAndKeyboardOperability': { modules: siteAssetsModules },
	'specs.thunderbolt.magnifyKeyboardOperability': { modules: siteAssetsModules },
	'specs.thunderbolt.fixGoogleMapLanguage': { modules: siteAssetsModules },
	// ------- catharsis experiments -------
	'specs.thunderbolt.compCssMappers_catharsis': { modules: siteAssetsModules },
	'specs.thunderbolt.one_cell_grid_display_flex': { modules: siteAssetsModules },
	'specs.thunderbolt.one_cell_grid_display_block': { modules: siteAssetsModules },
	'specs.thunderbolt.responsiveLayout_optimize_css': { modules: siteAssetsModules },
	'specs.thunderbolt.catharsis_theme_optimize_css': { modules: siteAssetsModules },
	'specs.thunderbolt.comp_designCss_selectorToCss_Mappers_catharsis': { modules: siteAssetsModules },
	'specs.thunderbolt.ooiCssAsLinkTag': { modules: siteAssetsModules },
	'specs.thunderbolt.mesh_css_catharsis': { modules: siteAssetsModules },
	'specs.thunderbolt.motionFeature': { modules: siteAssetsModules },
	'specs.thunderbolt.motionBgScrub': { modules: siteAssetsModules },
	'specs.thunderbolt.motionVeloShowHide': { modules: siteAssetsModules },
	'specs.thunderbolt.dynamicLoadTpaFeature': { modules: siteAssetsModules },
	'specs.thunderbolt.sandboxForCustomElement': { modules: siteAssetsModules },
	'specs.thunderbolt.fixCustomElementInWidgetInRepeater': { modules: siteAssetsModules },
	'specs.thunderbolt.forceStudio': { modules: siteAssetsModules },
	'specs.thunderbolt.accordionHeightAuto': { modules: siteAssetsModules },
	'specs.thunderbolt.fixComboBoxInRepeater': { modules: siteAssetsModules },
	'specs.thunderbolt.facebookVideoPlayerDimensions': { modules: siteAssetsModules },
	'specs.thunderbolt.minMaxInCheckboxGroup': { modules: siteAssetsModules },
	'specs.thunderbolt.newVhCalc': { modules: siteAssetsModules },
	'specs.thunderbolt.noHeightOnTextMask': { modules: siteAssetsModules },
	'specs.thunderbolt.WRichTextVerticalTextNowidth': { modules: siteAssetsModules },
	'specs.thunderbolt.newSpxResolving': { modules: siteAssetsModules },
})

/*
 * This list contains experiments names that are relevant to code running in site-assets (A.K.A Becky)
 * They are available via envApi.getExperiments() for carmi code and compInfo['experiments'] for components mappers
 * If the code that uses the experiment runs in feature - you don't need to add it here
 * See becky experiment guidelines here: https://wix.slack.com/canvas/C4D8A09PZ
 * */
export type beckyWhitelistSpecType = keyof typeof beckySpecs
export const beckyWhitelist = Object.keys(beckySpecs) as Array<beckyWhitelistSpecType>

/*
 * This list contains experiments names that are relevant to data-fixer code running in site-assets
 * They are passed to becky (site-assets modules) so that it can get fixed JSONs as input
 * Add only data-fixers experiments relevant to TB (they will be sent only if the experiment is open in thunderbolt-viewer scope)
 * */
export const dataFixersSpecsWhitelist = [
	'sv_migrateTpaToSemiNative',
	'bv_migrateAbsoluteLayoutToDataMaps',
	'bv_scrollEffectsFixer',
	'bv_migrate',
	'bv_migrateResponsiveLayoutToSingleLayoutData',
	'dm_removeMissingResponsiveRefs',
	'bv_removeMenuDataFromPageJson',
	'dm_fixMobileSplitInVariantNs',
	'dm_fixMobileSplitDesign',
	'dm_migrateToTextTheme',
	'dm_keepChildlessAppWidget',
	'dm_removeResponsiveDataFromClassicEditorFixer',
	'dm_enableDefaultA11ySettings',
	'dm_linkRelDefaults',
	'specs.thunderbolt.use_data_fixed_pages_upstream',
]

/*
 * This list contains experiments names that are relevant to data-fixer code running in site-assets
 * They are passed to becky (site-assets modules) so that it can get fixed JSONs as input
 * These experiments are always sent, regardless of conduction in thunderbolt-viewer scope.
 * */
export const hardCodedListOfDataFixersExperiments: Experiments = {
	bv_remove_add_chat_viewer_fixer: 'new',
}
